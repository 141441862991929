import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'
import {KGlackinTestimonial} from '../components/testimonial';
import AppStoreIcons from '../components/app-store-icons';

import remoteEmployeeSrc from '../images/remote-employee.svg'
import clockInAnywhereSrc from '../images/clock-in-anywhere.svg';
import leaveCalendarSrc from '../images/leave-calendar.svg';

import FreeTrialButton from '../components/free-trial-button';

const RemoteEmployees = () => (
  <Layout>
    <SEO
    	title="Remote Employee Time and Attendance"
    	description="Record time and attendance for distributed teams via web, mobile or tablet."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            Time and Attendance for
			            <br />
			            <span className="text-highlight-600">remote teams</span>
			          </h2>
			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            TimeKeeper helps you keep your distributed team's time and attendance in sync.
			          </p>

			          <FreeTrialButton />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={remoteEmployeeSrc} alt="Remote Employee at Computer" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
					<LeftFeature
				    	sectionTitle="Clock In and Out Every Device"
				    	title="Clock In on Web, Mobile and Kiosk"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          Configure your employees so that they can clock in and out via their web browser, mobile app or the TimeKeeper Kiosk on a tablet.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	This allows you to keep your on-premise employees clocking in via their kiosk, while your remote employees
						        	clock via web or mobile.
						        </FeaturePararaph>
						        <AppStoreIcons />
						    </div>
				    	}
				    	imgSrc={clockInAnywhereSrc}
				    	imgAlt="Clock In on Web, Tablet or Mobile"
				    />
				    <RightFeature
				    	sectionTitle="Keep on top of your team leave"
				    	title="Live interactive leave wall calendar"
				    	includeShadow={true}
				    	body={
				    		<div>
					    		<FeaturePararaph>
						        	Ensure your distributed team is on the same page with their leave and their team's leave.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	Employees can request leave and you can approve or decline employee leave requests via the web portal.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	Employees can see who's else is booked off in their team as well as keep track of their remaining holidays.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={leaveCalendarSrc}
				    	imgAlt="Leave Calendar "
				     />
				</div>
				<KGlackinTestimonial />
				<FreeTrial
					part1={
						<span>Legacy systems distributing your time <br /> and attendance processes?</span>
					}
					part2={<span>Start your 14 day free trial to keep your team in sync</span>}
				/>
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default RemoteEmployees
